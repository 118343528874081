import { useState } from "react";
import { useEffect } from "react";

const MobilePortfolioItems = () => {
       
    return ( 
        <div className="mobilePortfolioItems">
            <div className="mobileInnerPortfolioItems">
                <div className="projectTitle flex-column d-flex justify-content-center align-items-center">
                    <button>1. Mero  Biruwa</button>
                    
                </div>
            </div>   
        </div>
     );
}
 
export default MobilePortfolioItems;